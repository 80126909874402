"use client"

import SelectMenu, { RESET_VALUE } from "@/components/ui-library/menu/SelectMenu"
import { updateURLParameters } from "@/lib/updateURLParameters"
import { FilterTagStory } from "@/types/NonSyncedTypes"
import { usePathname, useRouter, useSearchParams } from "next/navigation"

type FilterProps = {
  tags: FilterTagStory[] | null
  placeholder: string
  allLabel: string
  param: string
  className?: string
  selectedValue?: string
  changeHandler?: (value: string) => void
}

type FilterItem = {
  label: string
  value: string
}

export const FilterMenu = ({
  tags,
  placeholder,
  allLabel,
  param,
  className,
  selectedValue,
  changeHandler
}: FilterProps) => {
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()
  const filter = searchParams.get(param)

  const selectedFilter = selectedValue ?? filter ?? RESET_VALUE

  if (!tags) return null

  const allItem = {
    label: allLabel,
    value: RESET_VALUE
  }

  const itemTags: FilterItem[] = tags.map(tag => {
    return {
      label: tag.name,
      value: tag.id
    }
  })

  const items: FilterItem[] = [allItem, ...itemTags]

  const defaultChangeHandler = (value: string) => {
    const currentUrl = `${process.env.HOME_URL}${pathname}?${searchParams}`
    const updatedParams = { [param]: value }
    const updatedUrl = updateURLParameters(currentUrl, updatedParams)

    router.push(updatedUrl)
  }

  const handleChange = changeHandler ?? defaultChangeHandler

  return (
    <SelectMenu
      items={items}
      placeholder={placeholder}
      onChange={value => handleChange(value as string)}
      selectedFilter={selectedFilter}
      param={param}
      className={className}
    />
  )
}

export default FilterMenu
